import React, {ReactNode} from "react"
import { repeatedElement } from '@plasmicapp/loader-gatsby'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export default function ImagesSlider({
	className,
	title,
	children
}: {
	className?: string,
	title?: string,
	children: ReactNode
}) {

	const slideStyles = {
		width: '100%',
		height: '100px',
		paddingTop: `${1.5 & 100}%`,
		borderRadius: '5px',
		backgroundSize: 'contain',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat'
	}

	const sliderSettings = {
		slidesToShow: 3,
		slidesToScroll: 1,
		infinite: true,
		arrows: true,
		dots: false,
		speed: 500,
		responsive: [
			// Mobile
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
				}
			},
			// Tablet
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	}

	return (
		<div className={className}>
			{/* <h1>Hello, im coming from react: {title}</h1> */}
			<SlickSlider {...sliderSettings}>
				<div>
					<div style={{
						width: '100%',
						padding: '20px',
					}}>
						<div
								style={{
									backgroundImage: `url(https://photologo.co/wp-content/uploads/2021/12/Photologo_Sig_1-min.png)`,
									...slideStyles
								}}
						/>
					</div>
				</div>
				<div>
					<div style={{
						width: '100%',
						padding: '20px',
					}}>
						<div
								style={{
									backgroundImage: `url(https://photologo.co/wp-content/uploads/2021/12/Photologo_Sig_2-min.png)`,
									...slideStyles
								}}
						/>
					</div>
				</div>
				<div>
					<div style={{
						width: '100%',
						padding: '20px',
					}}>
						<div
								style={{
									backgroundImage: `url(https://photologo.co/wp-content/uploads/2021/12/Photologo_Sig_5-min.png)`,
									...slideStyles
								}}
						/>
					</div>
				</div>
				<div>
					<div style={{
						width: '100%',
						padding: '20px',
					}}>
						<div
								style={{
									backgroundImage: `url(https://photologo.co/wp-content/uploads/2021/12/Photologo_Sig_9-min.png)`,
									...slideStyles
								}}
						/>
					</div>
				</div>
			</SlickSlider>
	</div>
	)
}
