let csrfToken = null
// let initFetch = {}
export default class Api {
  constructor(api_base_url){
    this.api_url = api_base_url;
    this.fetchWithRetry(this.api_url+'/api/getcsrftoken')
    .then(res => res.json())
    .catch(error => {
        console.error('Error:', error);
    })
    .then(response => {
			console.log(response)
			csrfToken = response.csrfToken;
			console.log(csrfToken)
			this.initFetch =  {
				method: 'POST',
				headers:{
						'Accept': 'application/json',
						'Content-Type': 'application/json',
						'X-CSRF-Token': csrfToken
				},
				mode: 'cors',
				credentials: 'include',
			}
    })
  }
  fetchWithRetry = (url) => {
    return new Promise((resolve, reject) => {
      let attempts = 1;
      const fetch_retry = (url, n) => {
        return fetch(url,{
            headers:{
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            method: 'GET',
            credentials:'include'
        }).then(resolve).catch(function (error) {
        if (n === 1) reject(error)
        else
        setTimeout(() => {
          attempts ++
          fetch_retry(url, n - 1);
        }, attempts * 3000)
      });
    }
      return fetch_retry(url, 5);
    });
  }

  // Get images
  getImages = async (type = null,gallery=null, callback) => {

    const data = {type, gallery};
    this.initFetch.body = JSON.stringify(data);
     await fetch(this.api_url+'/api/getImages',this.initFetch)
    .then(res => res.json())
    .catch(error => {
        console.log(error);
    }).then(response => {
        callback(response);
    });
  }
  // Create an order
  createOrder =  async ({
    email,
    voucher,
    lines,
    firstName,
    lastName,
    phone='',
    country,
    recaptchaResponse
    }, callback, handleError) => {
    var data = {email, voucher, lines, firstName, lastName, phone, country, recaptchaResponse};
    this.initFetch.body = JSON.stringify(data);
    await fetch(this.api_url+'/api/createOrder',this.initFetch)
    .then(res => res.json())
    .catch(error =>{
        handleError(error);
    }).then(response => {
        if(response.hasOwnProperty('networkError')){
            if(response.networkError != null){
                console.error(response);
                handleError(response.message);
            }
        }
        if(response.hasOwnProperty('graphQLErrors')){
            console.log(response.graphQLErrors);
            handleError(response.message);
        }else{
            callback(response);
        }
    });
  }
  processPayment = async (
    orderId,
    nonce,
    deviceData,
    callback
    ) => {
      var data = {orderId, nonce, deviceData};
      this.initFetch.body = JSON.stringify(data);
      await fetch(this.api_url+'/api/processPayment',this.initFetch)
      .then(res => res.json())
      .catch(error => {
          console.error('Error:', error)
      }).then(response => {
          callback(response.data.echkoPaymentBraintreeProcessPayment);
      });
  }
}
