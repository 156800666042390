import React, {useState, useEffect } from "react";
import Api from '../api/api';
import braintree from "braintree-web-drop-in";

export default function PaymentTest () {
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [braintreeClient, setBraintreeClient] = useState(null);
  const [orderCreated, setOrderCreated] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

/*   useEffect(() => {
    createOrder();
  }, []); */
  const country = 'CO';
	const api = new Api('https://api.dev.photologo.co');

  const buttonStyles = {
    button: {
      cursor: 'pointer',
      fontWeight: 500,
      margin: '0 auto',
      lineHeight: 'inherit',
      // position: 'relative',
      textDecoration: 'none',
      // textAlign: 'center',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '3px',
      display: 'flex'
    },
    buttonSmall: {
      padding: '10px 20px',
      fontSize: '0.875rem'
    },
    buttonGreen: {
      outline: 'none',
      backgroundColor: '#64d18a',
      borderColor: '#64d18a',
      color: 'white',
      transition: 'all 200ms ease'
    }
  }
  const inputStyles = {
    input: {
      // position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      paddingBottom: '35px',
      marginTop: '40px',
      padding: '0 15px',
      height: '42px',
      lineHeight: '42px',
      fontSize: '16px',
      width: '100%',
      maxWidth: '550px',
      textAlign: 'center',
      fontWeight: '400',
      border: '2px solid #c7c9c8',
      transition: 'border-color .3s ease-out',
      boxShadow: 'inset 0 2px 1px rgba(0,0,0,.025)',
    },
  }
  const createOrder = async (e) => {
    setPaymentLoading(true);
    const orderData = {
      email,
      lines: [
        {
          data: '{"RNGThickThin":"any","RNGCurvyStraight":"any","RNGProfesionalPlayful":"any","RNGReadableAbstract":"any","RNGClassicModern":"any","text":"test","style":"RXhoaWJpdGlvbjo4ODE=","undertag":"","clientNotes":"","industry":"Accounting","purpose":"","currentLogo":"","whereWillBeUsed":"","team":"","sameStyle":"yes","hotjarID":"e5652164","from":"localhost:8000/purchase","phone":"+584247344397","lp_target":null,"experiment":"none","ad":""}',
          quantity: 1,
          variantId: 'UHJvZHVjdFZhcmlhbnQ6NzY='
        },
        {
          quantity: 1,
          variantId: 'UHJvZHVjdFZhcmlhbnQ6ODY='
        }
      ],
      voucher: null,
      firstName,
      lastName,
      country,
      recaptchaResponse: '03AGdBq24uVF92fe27_0J7KuCUvFVT4gmzVktG-EVyzk509_YN7iiXc83LXQSmrjuYYVe9IC0Ltpym260Z0ObkCIy_PRyOj4zG0-uXQ1Mrm77DsfAf6f0RXgiYtvl8jS4ZMdOyQVBpgxBDTw_dqjwUikicDIewZ5P0Ji2ILAnzNqm1nxuW_8a-egbUOe73AHWwUPtpcN3UhlNCjyLD-QaWp7Ie5yWOxtdyaz8u3UDILWiCIOBKW9ek3xkb-u7EbPpK_MNOduWfwjiRP7FH3zaQkn_eVMvmYH5stuwYgsKVWWAIPuUPW3SNqB8W6HgL65m7xvfQStusvF1ref7kJ87q0nFv7S5VUE4SA4q_Q8QVFe7qWZgZatmfhP1mwyUCDZn5i3Mk4WwP4NPOQi14mWoB-ECC4NKTnqhFT2ui4OJxxscn5bZprK0jRdyPDO6awptz0kA3oQFytd8X'
    }
    api.createOrder(
      orderData,
      (response) => { // Callback
          // TODO: Validate response
          console.log('echkoCreateOrder: ', JSON.stringify(response));
          const order = response.data.echkoCreatePhotologoOrder.order;
          if(order != null){
              const orderId = order.id;
              const orderReference = order.referenceId;
              const processor = order.gatewayProcessInfo.gateway
              const braintreeToken = order.gatewayProcessInfo.token
              setOrderCreated({orderId, orderReference, braintreeToken});
              if (processor==='braintree'){
                  initBraintree(braintreeToken);
              }else{
                console.log('other payment gateway different than braintree')
              }

          }else{
              console.error('Error null order');
              setPaymentLoading(false);
          }
      },
      (err) => {
          console.error('Error creating order', err);
          setPaymentLoading(false);
      }
    )
	}

	const initBraintree = (token) => {
		braintree.create({
			authorization: token,
			selector: '#dropin-container'
		}, function (err, dropinInstance) {
			if(err){
					console.log('err1',err);
				return;
			}
			setBraintreeClient(dropinInstance);
		});
	}
	const processPayment = () =>{
		let errorMessage= '';
		braintreeClient.requestPaymentMethod(function (err, payload) {
			if(err){
					console.log('err',err);
				return;
			}
				console.log(payload);
				api.processPayment(orderCreated.orderId, payload.nonce, null, (response) => {
					console.log('payment response: ', response);
					// Successful payment
					if (response.isSuccess) {
						 console.log('payment accepted');
						 alert('payment accepted');
					}
					else {
							setPaymentLoading(false);
							try {
									errorMessage = JSON.parse(response.errors[0].message);
							} catch(e) {
									errorMessage = 'Error trying to get error message';
									console.log('Error trying to show error message');
							}
					}
				})
			});
	}

	return(
		<div
			style={{
				display: 'flex',
				justifyContent:'center',
				marginTop: '50px',
				marginBottom:'50px',
				marginLeft:'auto',
				marginRight:'auto',
				flexDirection:'column',
				maxWidth:'450px',
				whiteSpace: 'normal'
			}}
		>
			<h1>Payment</h1>
			<Input
				setRef={null}
				handleErrorChange={null}
				name='email'
				error={null}
				placeholder='Type Your Email Address Here'
				handleChange={setEmail}
				value={email}
				type='email'
				style={{inputStyles}}
				required
			/>

			<Input
				setRef={null}
				handleErrorChange={null}
				name='firstname'
				error={null}
				placeholder='First Name'
				handleChange={setFirstName}
				value={firstName}
				type='text'
				style={{inputStyles}}
				required
			/>

			<Input
				setRef={null}
				handleErrorChange={null}
				name='lastname'
				error={null}
				placeholder='Last Name'
				handleChange={setLastName}
				value={lastName}
				type='text'
				style={{inputStyles}}
				required
			/>
			{orderCreated == null && 
				<div style={{margin:'20px auto 0 auto'}}>
					<button
						style={{
							...buttonStyles.button,
							...buttonStyles.buttonSmall,
							...buttonStyles.buttonGreen
						}}
						className="button button--small button--green"
						onClick={createOrder}
					>
						Continue
					</button>
				</div>
			}
			{orderCreated &&
				<div>
					<div id="dropin-container"></div>
					<div style={{margin:'20px auto 0 auto'}}>
						<button
							style={{
								...buttonStyles.button,
								...buttonStyles.buttonSmall,
								...buttonStyles.buttonGreen
								}}
							id="submit-button"
							className="button button--small button--green"
							onClick={processPayment}
						>
							Purchase
						</button>
					</div>
				</div>
			}
		</div>
	)
}

const Input = (props) => {

	const {
			UIVariation,
			type='text',
			placeholder,
			handleChange,
			setRef=null,
			required,
			value,
			defaultValue,
			error = false,
			name,
			style,
			handleErrorChange = () => {},
			autofocus = false,
	} = props;
	return (
			<div className={`Input ${UIVariation == 'brand-shift-1' ? 'Input--bs' : ''} ${error ? 'Input--error' : ''}`}>
					<input
							data-lpignore='true'
							autoFocus={autofocus}
							className={`Input__input`}
							ref={setRef}
							id={`input_${name}`}
							type={props.type ? type : 'text'}
							placeholder={placeholder}
							onChange={e => {handleChange(e.target.value);}}
							required={required}
							name={name}
							value={value}
							defaultValue={defaultValue}
							style={{...style.inputStyles.input}}
					/>
			</div>
	);
}